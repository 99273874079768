<template>
  <v-container fluid>
    <v-snackbar :value="isError" color="error">
      {{ isError ? getError.errorMessage : '' }}
      <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog v-model="showCuadrarVentaConfirmation" max-width="320">
      <v-card>
        <v-card-title class="headline">Cuadrar Inventario?</v-card-title>

        <v-card-text>
          <p>Estás seguro? Esta información será registrada en la Base de Datos y el stock disponible será devuelto a la Tienda.</p>
          <p class="font-weight-bold">
            Producto:
            <span class="font-weight-light">{{cerrarVentaItem.nombreProducto}}</span>
          </p>
          <p class="font-weight-bold">
            Stock Asignado:
            <span class="font-weight-light">{{cerrarVentaItem.stockAsignado}}</span>
          </p>
          <p class="font-weight-bold">
            Stock Disponible:
            <span class="font-weight-light">{{cerrarVentaItem.stockActual}}</span>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="green darken-1" text @click="showCuadrarVentaConfirmation = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cuadrarVenta">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEliminarUsuarioDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">Eliminar Usuario</v-card-title>

        <v-card-text>
          <p>Estás seguro? La eliminación solo será posible si no tiene ningún registro asociado. Su información no se podrá recuperar.</p>
          <p class="font-weight-light">
            Nombre del Trabajador:
            <span
              class="font-weight-bold"
            >{{rowSeleccionado.item ? rowSeleccionado.item.nombreTrabajador : ''}}</span>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="green darken-1" text @click="showEliminarUsuarioDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="eliminarUsuario">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eliminarProductoDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">Eliminar Producto</v-card-title>

        <v-card-text>
          <p>¿Estás seguro? También se eliminará el producto vacio asociado.</p>
          <p class="font-weight-light">
            Producto:
            <span class="font-weight-bold">{{eliminarProductoModel.nombreProducto}}</span>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="eliminarProductoDialog = false"
            :disabled="isLoading"
          >Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="eliminarProducto"
            :loading="isLoading"
            :disabled="isLoading"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNuevoTrabajadorDialog" width="550px">
      <v-card>
        <v-card-title>Crear Nuevo Trabajador</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field outlined dense label="Nombres" v-model="nuevoTrabajadorModel.nombre"></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Apellidos"
                  v-model="nuevoTrabajadorModel.apellidos"
                ></v-text-field>
                <v-select
                  :items="getListaTiendas"
                  item-text="nombreEstablecimiento"
                  item-value="idEstablecimiento"
                  outlined
                  dense
                  label="Establecimiento Asignado"
                  v-model="nuevoTrabajadorModel.idEstablecimiento"
                ></v-select>
                <v-select
                  :items="['Usuario', 'Administrador', 'Supervisor']"
                  outlined
                  dense
                  label="Rol"
                  v-model="nuevoTrabajadorModel.rol"
                ></v-select>
                <v-text-field
                  outlined
                  dense
                  label="Contacto"
                  v-model="nuevoTrabajadorModel.contacto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field outlined dense label="Usuario" v-model="nuevoTrabajadorModel.usuario"></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Contraseña"
                  v-model="nuevoTrabajadorModel.password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showNuevoTrabajadorDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="crearTrabajador"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showMovimientoProductoDialog" width="500px">
      <v-card>
        <v-card-title>Movimiento de Producto</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-form v-model="movimientoProductoFormValid" ref="movimientoProductoForm">
              <v-row>
                <v-col cols="12" sm="12" md="6" class="pb-0">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    label="Nombre del Producto"
                    v-model="productoSeleccionadoMovimiento.producto.nombreProducto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="pb-0">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    label="Stock de Producto"
                    :value="valorMovimientoProducto()"
                    :rules="[v => (v >= 0) || 'El stock no puede ser negativo']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="py-0">
                  <v-select
                    :items="['Entrada', 'Salida']"
                    outlined
                    dense
                    v-model="productoSeleccionadoMovimiento.tipoMovimiento"
                    label="Tipo de Movimiento"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    label="Cantidad a Mover"
                    hint="Tiene que ser mayor a 0"
                    v-model="productoSeleccionadoMovimiento.cantidad"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showMovimientoProductoDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="asignarMovimientoPersona"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="asignarProductoDialog" width="550px">
      <v-card>
        <v-card-title>Asignar Producto</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  :items="listaProductosHabilitados"
                  item-text="nombreProducto"
                  item-value="idProducto"
                  outlined
                  dense
                  label="Producto a Asignar"
                  v-model="nuevoProductoAsignadoModel.idProducto"
                ></v-select>
                <v-text-field
                  outlined
                  dense
                  hint="Tiene que ser mayor o igual a 0"
                  label="Cantidad Inicial"
                  v-model="nuevoProductoAsignadoModel.cantidad"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="asignarProductoDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="asignarProductoPersona"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="recuperarPasswordDialog" width="450px">
      <v-card>
        <v-card-title>Recuperar Contraseña</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  hint="Escribe la nueva contraseña para el usuario seleccionado"
                  label="Nueva Contraseña"
                  v-model="nuevoPasswordModel.password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="recuperarPasswordDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="cambiarPasswordPersona"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          fixed-header
          :headers="headers"
          :items="listaPersonasHabilitadas"
          :loading="isLoading"
          loading-text="Cargando... Espera un Momento"
          no-data-text="No hay datos para mostrar"
          :search="buscarTabla"
          item-key="idTrabajador"
          single-expand
          single-select
          show-expand
          class="elevation-1"
          @item-expanded="whenExpanded"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-toolbar-title>PERSONAS</v-toolbar-title>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" dark @click="showNuevoTrabajadorDialog = true">
                Agregar
                <v-icon right>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-layout class="py-3 d-flex">
              <v-row>
                <v-col cols="12" sm="12" md="9">
                  <v-text-field
                    v-model="buscarTabla"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar en la tabla..."
                    class="mx-4 flex-shrink-0 flex-grow-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-switch
                    v-model="showSoloPersonasHabilitadas"
                    :label="showSoloPersonasHabilitadas ? 'Personas Habilitadas' : 'Personas Inhabilitadas'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.fechaInicio="{ item }">{{ formatDateTable(item.fechaInicio) }}</template>
          <template v-slot:item.activo="{ item }">
            <v-chip :color="getColorActivo(item)" dark>{{item.activo ? 'SI': 'NO'}}</v-chip>
          </template>
          <template v-slot:expanded-item="{ headers, item }" style="width: 100%">
            <td :colspan="headers.length" v-if="expandedLoading">
              <v-skeleton-loader ref="expSkeleton" type="article" class="mx-auto"></v-skeleton-loader>
            </td>
            <td :colspan="headers.length" v-else>
              <v-layout>
                <v-row>
                  <v-col cols="12" sm="12" class="pb-0 grey lighten-4 py-4">
                    <div v-if="showCambioEstablecimientoDialog" class="d-flex">
                      <v-select
                        :items="getListaTiendas"
                        item-text="nombreEstablecimiento"
                        item-value="idEstablecimiento"
                        outlined
                        dense
                        label="Establecimiento Asignado"
                        v-model="nuevoEstablecimiento"
                        class="flex-grow-0 mx-2"
                        hint="Si el botón Guardar está desactivado, aún tienes productos asignados o está en el Almacén"
                        persistent-hint
                      ></v-select>
                      <v-btn
                        class="mx-2"
                        color="success"
                        :disabled="!!itemAsignadosTrabajador.length || item.idEstablecimiento == 1 || isLoading"
                        :loading="isLoading"
                        @click="cambiarEstablecimiento"
                      >Guardar</v-btn>
                      <v-btn
                        class="mx-2"
                        text
                        @click="showCambioEstablecimientoDialog = false"
                      >Cancelar</v-btn>
                    </div>
                    <div v-else class="d-flex pb-4">
                      <p class="my-1">
                        Establecimiento Asignado:
                        <span
                          class="font-weight-black"
                        >{{item.nombreEstablecimiento}}</span>
                      </p>
                      <v-btn
                        icon
                        text
                        class="mx-2"
                        small
                        color="green"
                        @click="showCambioEstablecimiento"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="7" class="pt-0">
                    <v-subheader>Lista de Productos Asignados</v-subheader>
                    <v-data-table
                      dense
                      :items="itemAsignadosTrabajador"
                      :headers="headersStockAsignado"
                      item-key="idProducto"
                      hide-default-footer
                    >
                      <template v-slot:item.action="{ item }">
                        <v-btn
                          text
                          icon
                          small
                          class="mx-2"
                          color="primary"
                          :disabled="!!!item.stockActual && !!!item.stockAsignado "
                          @click="cerrarVentasProducto(item)"
                        >
                          <v-icon>mdi-clipboard-check</v-icon>
                        </v-btn>
                        <v-btn
                          text
                          icon
                          class="mx-2"
                          color="green"
                          small
                          @click="movimientoProducto(item)"
                        >
                          <v-icon>mdi-home-export-outline</v-icon>
                        </v-btn>
                        <v-btn
                          text
                          icon
                          small
                          class="mx-2"
                          color="red"
                          v-if="item.nombreProducto.indexOf('Vacio') == -1"
                          @click="showEliminarProductoDialog(item)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" sm="12" md="5" class="pt-0">
                    <v-subheader>
                      Cantidad de Productos en Tienda
                      <v-btn text icon @click="showDetalleTienda = !showDetalleTienda" class="mx-2">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-data-table
                      v-show="showDetalleTienda"
                      dense
                      :items="detalleTienda.productos"
                      :headers="headersListaProductos"
                      item-key="idProducto"
                      hide-default-footer
                    >
                      <template v-slot:item.costoBase="{ value }">S/ {{value.toFixed(2)}}</template>
                    </v-data-table>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    class="d-flex justify-end"
                    :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-btn
                      outlined
                      color="blue"
                      class="ma-2"
                      @click="asignarProductoDialog = true"
                      :disabled="!listaProductosHabilitados.length"
                    >
                      <v-icon left>mdi-cart-plus</v-icon>Asignar Producto
                    </v-btn>
                    <v-btn
                      outlined
                      color="teal"
                      class="ma-2"
                      @click="recuperarPasswordDialog = true"
                    >
                      <v-icon left>mdi-lock-reset</v-icon>Recuperar Contraseña
                    </v-btn>
                    <v-btn
                      outlined
                      :color="item.activo ? 'red' : 'blue'"
                      :loading="isLoading"
                      :disabled="isLoading"
                      @click="cambiarEstadoActivoPersona"
                      class="ma-2"
                    >
                      <v-icon
                        left
                        :color="item.activo ? 'red' : 'blue'"
                      >{{ item.activo ? 'mdi-account-cancel' : 'mdi-account-check'}}</v-icon>
                      {{ item.activo ? 'Deshabilitar Persona' : 'Habilitar Persona'}}
                    </v-btn>
                    <v-btn
                      outlined
                      @click="showConfirmEliminarUsuario"
                      color="red"
                      class="ma-2"
                      v-if="!item.activo"
                    >
                      <v-icon left>mdi-delete-forever</v-icon>Eliminar Usuario
                    </v-btn>
                  </v-col>
                </v-row>
              </v-layout>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    buscarTabla: '',
    trabajadores: [],
    headers: [
      { text: 'ID', value: 'idTrabajador', align: 'center' },
      { text: 'Usuario', value: 'usuario', align: 'center' },
      { text: 'Nombre', value: 'nombreTrabajador', align: 'center' },
      {
        text: 'Establecimiento Asignado',
        value: 'nombreEstablecimiento',
        align: 'center'
      },
      { text: 'Rol', value: 'rol', align: 'center' },
      {
        text: 'Contacto',
        value: 'contacto',
        align: 'center'
      },
      { text: 'Fecha y Hora de Inicio', value: 'fechaInicio', align: 'center' },
      { text: 'Activo', value: 'activo', align: 'center' },
      { text: '', value: 'data-table-expand' }
    ],
    headersStockAsignado: [
      { text: 'Producto', value: 'nombreProducto', align: 'center' },
      { text: 'Stock Asignado', value: 'stockAsignado', align: 'center' },
      { text: 'Stock Disponible', value: 'stockActual', align: 'center' },
      { text: 'Acciones', value: 'action', align: 'left', sortable: false }
    ],
    infoSnackbar: false,
    infoSnackbarMessage: '',
    showNuevoTrabajadorDialog: false,
    showDetalleTienda: true,
    nuevoTrabajadorEmpty: {
      nombre: '',
      apellidos: '',
      contacto: '',
      activo: 1,
      idEstablecimiento: 0,
      rol: '',
      usuario: '',
      password: ''
    },
    nuevoTrabajadorModel: {
      nombre: '',
      apellidos: '',
      contacto: '',
      activo: 1,
      idEstablecimiento: 0,
      rol: '',
      usuario: '',
      password: ''
    },
    asignarProductoDialog: false,
    nuevoProductoAsignadoModel: {
      idProducto: 0,
      cantidad: 0
    },
    itemAsignadosTrabajador: [],
    expandedLoading: false,
    idTrabajadorSeleccionado: 0,
    detalleTienda: {},
    showMovimientoProductoDialog: false,
    headersListaProductos: [
      { text: 'Producto', value: 'nombreProducto', align: 'center' },
      { text: 'Stock Total', value: 'stockProducto', align: 'center' },
      { text: 'Stock Disponible', value: 'stockDisponible', align: 'center' }
    ],
    listaProductosHabilitados: [],
    productoSeleccionadoMovimiento: {
      tipoMovimiento: '',
      producto: {},
      cantidad: 0
    },
    rowSeleccionado: {},
    movimientoProductoFormValid: true,
    showCuadrarVentaConfirmation: false,
    cerrarVentaItem: {},
    showSoloPersonasHabilitadas: true,
    showEliminarUsuarioDialog: false,
    showCambioEstablecimientoDialog: false,
    nuevoEstablecimiento: 0,
    eliminarProductoModel: {},
    eliminarProductoDialog: false,
    recuperarPasswordDialog: false,
    nuevoPasswordModel: {
      password: ''
    }
  }),

  methods: {
    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    getColorActivo(item) {
      if (item.activo) return 'green'
      else return 'red'
    },

    async actualizarTabla() {
      this.trabajadores = await this.fetchDataTrabajadores()
    },

    async crearTrabajador() {
      let response = await this.postNuevoTrabajador(this.nuevoTrabajadorModel)
      if (response) {
        this.showNuevoTrabajadorDialog = false
        this.nuevoTrabajadorModel = { ...this.nuevoTrabajadorEmpty }
        await this.actualizarTabla()
      }
    },

    async whenExpanded(row) {
      if (row.value) {
        this.showCambioEstablecimientoDialog = false
        this.expandedLoading = true
        this.rowSeleccionado = row
        this.idTrabajadorSeleccionado = row.item.idTrabajador
        let response = await this.fetchStockAsignado(
          this.idTrabajadorSeleccionado
        )
        this.itemAsignadosTrabajador = response
        this.detalleTienda = await this.fetchDetalleTienda(
          row.item.idEstablecimiento
        )
        let { ...listaProdTienda } = this.detalleTienda
        let [...prodFiltrados] = this.getListaProductosFiltrada
        let listaProdsHabilitados = prodFiltrados.filter(function(item) {
          let { ...auxItem } = item
          return !response.some(i => auxItem.idProducto == i.idProducto)
        })

        this.listaProductosHabilitados = listaProdsHabilitados.filter(function(
          item
        ) {
          let { ...auxItem } = item
          return listaProdTienda.productos.some(
            i => auxItem.idProducto == i.idProducto
          )
        })

        this.expandedLoading = false
      }
    },

    movimientoProducto(item) {
      this.productoSeleccionadoMovimiento = {
        tipoMovimiento: '',
        producto: {},
        cantidad: 0
      }
      this.showMovimientoProductoDialog = true
      let { ...itemSeleccionado } = item
      this.productoSeleccionadoMovimiento.producto = itemSeleccionado
    },

    valorMovimientoProducto() {
      let response = 0
      switch (this.productoSeleccionadoMovimiento.tipoMovimiento) {
        case 'Entrada':
          response =
            parseInt(this.productoSeleccionadoMovimiento.cantidad) +
            this.productoSeleccionadoMovimiento.producto.stockActual
          if (isNaN(response))
            response = this.productoSeleccionadoMovimiento.producto.stockActual
          break
        case 'Salida':
          response =
            this.productoSeleccionadoMovimiento.producto.stockActual -
            this.productoSeleccionadoMovimiento.cantidad
          break
        default:
          response = this.productoSeleccionadoMovimiento.producto.stockActual
          break
      }
      return response
    },

    async asignarMovimientoPersona() {
      if (this.$refs.movimientoProductoForm.validate()) {
        let response = await this.postMovimientoProductoPersonaTienda({
          idTrabajador: this.idTrabajadorSeleccionado,
          producto: this.productoSeleccionadoMovimiento
        })
        if (response) {
          this.showMovimientoProductoDialog = false
          await this.whenExpanded(this.rowSeleccionado)
          await this.actualizarTabla()
        }
      }
    },

    async asignarProductoPersona() {
      let response = await this.postAsignarNuevoProductoTrabajador({
        idTrabajador: this.idTrabajadorSeleccionado,
        producto: this.nuevoProductoAsignadoModel
      })
      if (response) {
        this.asignarProductoDialog = false
        this.nuevoProductoAsignadoModel = {
          idProducto: 0,
          cantidad: 0
        }
        await this.whenExpanded(this.rowSeleccionado)
        await this.actualizarTabla()
      }
    },

    async cambiarPasswordPersona() {
      let payload = {
        idTrabajador: this.idTrabajadorSeleccionado,
        body: this.nuevoPasswordModel
      }

      let response = await this.putCambiarPasswordTrabajador(
        this.cleanSource(payload)
      )

      if (response) {
        this.recuperarPasswordDialog = false
        this.nuevoPasswordModel = {
          password: ''
        }
        await this.whenExpanded(this.rowSeleccionado)
        await this.actualizarTabla()
      }
    },

    cerrarVentasProducto(item) {
      this.showCuadrarVentaConfirmation = true
      this.cerrarVentaItem = { ...item }
    },

    async cuadrarVenta() {
      let response = await this.postCuadrarVenta(this.cerrarVentaItem)

      if (response) {
        this.showCuadrarVentaConfirmation = false
        await this.whenExpanded(this.rowSeleccionado)
        await this.actualizarTabla()
        this.cerrarVentaItem = {}
      }
    },

    async cambiarEstadoActivoPersona() {
      let response = await this.putCambiarEstadoTrabajador(
        this.idTrabajadorSeleccionado
      )

      if (response) {
        await this.actualizarTabla()
        this.showSoloPersonasHabilitadas = !this.showSoloPersonasHabilitadas
      }
    },

    showConfirmEliminarUsuario() {
      this.showEliminarUsuarioDialog = true
    },

    async eliminarUsuario() {
      let response = await this.deleteTrabajor(
        this.rowSeleccionado.item.idTrabajador
      )
      if (response) {
        this.showEliminarUsuarioDialog = false
        this.infoSnackbar = true
        this.infoSnackbarMessage = response.message
        await this.actualizarTabla()
      }
    },

    async showCambioEstablecimiento() {
      if (!this.getListaTiendas.length) {
        await this.fetchListaTiendas()
      }
      this.nuevoEstablecimiento = this.rowSeleccionado.item.idEstablecimiento
      this.showCambioEstablecimientoDialog = true
    },

    async cambiarEstablecimiento() {
      if (this.nuevoEstablecimiento) {
        let response = await this.cambiarTiendaTrabajador({
          idTrabajador: this.rowSeleccionado.item.idTrabajador,
          idEstablecimiento: this.nuevoEstablecimiento
        })

        if (response) {
          this.rowSeleccionado.item.idEstablecimiento = this.nuevoEstablecimiento
          await this.whenExpanded(this.rowSeleccionado)
          await this.actualizarTabla()
          this.showCambioEstablecimientoDialog = false
        }
      }
    },

    showEliminarProductoDialog(item) {
      this.eliminarProductoModel = { ...item }
      this.eliminarProductoDialog = true
      console.log(this.eliminarProductoModel)
    },

    async eliminarProducto() {
      let payload = {
        idTrabajador: this.eliminarProductoModel.idTrabajador,
        idProducto: this.eliminarProductoModel.idProducto
      }
      let response = await this.deleteProductoTrabajador(payload)
      if (response) {
        await this.actualizarTabla()
        await this.whenExpanded(this.rowSeleccionado)
        this.eliminarProductoModel = {}
        this.eliminarProductoDialog = false
        console.log(this.eliminarProductoModel)
      }
    },

    ...mapActions('user', [
      'fetchDataTrabajadores',
      'postNuevoTrabajador',
      'fetchStockAsignado',
      'postAsignarNuevoProductoTrabajador',
      'postMovimientoProductoPersonaTienda',
      'postCuadrarVenta'
    ]),

    ...mapActions('tienda', ['fetchListaTiendas', 'fetchDetalleTienda']),

    ...mapActions('trabajador', [
      'putCambiarEstadoTrabajador',
      'deleteTrabajor',
      'cambiarTiendaTrabajador',
      'deleteProductoTrabajador',
      'putCambiarPasswordTrabajador'
    ])
  },

  computed: {
    listaPersonasHabilitadas() {
      if (this.showSoloPersonasHabilitadas) {
        return this.trabajadores.filter(item => item.activo)
      } else {
        return this.trabajadores.filter(item => !!!item.activo)
      }
    },

    ...mapGetters('user', [
      'isLoading',
      'isError',
      'getError',
      'getListaProductosFiltrada'
    ]),
    ...mapGetters('tienda', ['getListaTiendas'])
  },

  mounted: async function() {
    await this.actualizarTabla()
  }
}
</script>

<style>
</style>